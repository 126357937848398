import SchoolIcon from '@mui/icons-material/School';
import KayakingIcon from '@mui/icons-material/Kayaking';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ShieldIcon from '@mui/icons-material/Shield';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import DirectionsBusFilledIcon from '@mui/icons-material/DirectionsBusFilled';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
export const ourservices = [
  {
    id: 1,
    title: 'Study Tours',
    description:
      'Embark on Educational Adventures with Dynamic Coach. Our dedicated service specializes in providing safe and reliable transportation for school study tours.',
    href: '/services',
    icon: <MenuBookIcon className="text-white text-6xl" />,
  },
  {
    id: 2,
    title: 'Tours & Excursions',
    description:
      "Explore outdoor activities and thrilling cultural activities and experience unforgettable adventures with our Tours and Excursions.",
    href: '/services',
    icon: <KayakingIcon className="text-white text-6xl" />,
  },
  {
    id: 3,
    title: 'Bus Rental',
    description:
      'Streamline Your Group Travel with Bus Rental Services! Our reliable and convenient bus rental options are perfect for all your transportation needs.',
    href: '/services',
    icon: <DirectionsBusIcon className="text-white text-6xl" />,
  },
  {
    id: 4,
    title: 'School Bus',
    description:
      'Safe and Reliable School Bus Services for Your Students! Our priority is ensuring the safety and comfort of your students on their daily commute.',
    href: '/services',
    icon: <SchoolIcon className="text-white text-6xl" />,
  },
  {
    id: 5,
    title: 'Safety Tours',
    description:
      'Your Safety, Our Guarantee! We prioritize your well-being with stringent safety measures. From our meticulously maintained.',
    href: '/services',
    icon: <ShieldIcon className="text-white text-6xl" />,
  },
  {
    id: 6,
    title: 'Professional Staff',
    description:
      'Experience Excellence with Our Professional Staff! Our team is dedicated to provide exceptional service with expertise and care.',
    href: '/services',
    icon: <ThumbUpOffAltIcon className="text-white text-6xl" />,
  },
];
export const stepsToBook = [
  {
    id: 1,
    title: 'Choose your Date & time',

    icon: <AdsClickIcon className="text-white text-4xl" />,
  },
  {
    id: 2,
    title: 'Select your bus',
    icon: <DirectionsBusFilledIcon className="text-white text-4xl" />,
  },
  {
    id: 3,
    title: 'Pay your fare ',
    icon: <CurrencyPoundIcon className="text-white text-4xl" />,
  },
  {
    id: 4,
    title: 'Enjoy your Trip',
    icon: <InsertEmoticonIcon className="text-white text-4xl" />,
  },
];
